$color1: #f8f9fa;
$color2: #67046b;
$color3: rgb(4, 46, 90);
$color3-Hover: rgba(4, 46, 90, 0.637);
$font1: 14px;
$transition: .4s;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Courier New", Courier, monospace;
}

p {
  margin: 0;
}

body,
html {
  height: 100%;
}

.navbar {
  position: fixed;
  z-index: 1000;
  opacity: 0.7;
  background-color: $color1;
  width: 100%;

  p {
    margin-bottom: 0;
    cursor: pointer;
  }

  .container-fluid {
    width: 85%;
    margin-inline: auto;
    padding: 0;
  }
  .navbar-collapse {
    justify-content: right;
  }

  .nav-item {
    margin-inline: 8px;
    font-weight: bold;
  }

  .navbar-nav .nav-link {
    transition: .4s color;
    font-size: 17px;
    color: black;

    &:hover {
      color: $color2;
      text-decoration: underline;
    }
  }

  .dropdown-menu {
    p {
      font-size: 15px;
      font-weight: bold;

      img {
        width: 30px;
        object-fit: scale-down;
        margin-right: 2px;
        margin-bottom: 3px;
      }
    }
  }

  .active {
    color: $color2 !important;
    border-bottom: 2px solid #67046b;
  }
}

.about-us,
.our-services,
.career,
.contact {
  background-color: $color1;

  #carouselExampleCaptions {
    .carousel-item {
      background: linear-gradient(90deg, #021e3bc6, #5e1263);
      width: 100%;
      height: 50vh;
      // img {
      //   opacity: 0.9;
      //   width: 100%;
      //   height: 70vh;
      // }
    }
  }

  .about-us-common-part {
    width: 60%;
    border-radius: 20px;
    padding: 30px 5%;
    display: flex;
    align-items: center;
    color: black;
    div {
      animation: about-us 2s ease-in;
    }
    div:nth-child(1) {
      margin-right: 10%;
      i {
        font-size: 50px;
      }
    }

    p {
      font-style: italic;
      font-size: $font1;
    }
  }

  .carousel-caption {
    color: white;
    bottom: calc(25vh - 64px);

    h4 {
      font-size: 45px;
      width: 100%;
      animation: rightToLeft 2s ease-in;
    }
  }
}

.homePage {
  .carousel {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 3rem;
      height: 3rem;
    }

    .carousel-item {
      img {
        opacity: 0.9;
        width: 100%;
        height: 100vh;
      }
    }

    .carousel-indicators [data-bs-target] {
      background-color: white;
    }

    .carousel-indicators .active {
      background-color: $color2;
    }

    .carousel-indicators {
      bottom: 10px;
    }
    .carousel-caption {
      color: white;
      bottom: calc(50vh - 128px);
      h4 {
        font-size: 45px;
        margin-bottom: 20px;
        width: 100%;
        animation: rightToLeft 2s ease-in;
      }

      p {
        font-size: 25px;
        width: 100%;
        animation: leftToRight 2s ease-in;
      }
    }

    @keyframes rightToLeft {
      from {
        margin-left: 100%;
      }
      to {
        margin-left: 0;
      }
    }

    @keyframes leftToRight {
      from {
        margin-left: -100%;
      }
      to {
        margin-left: 0;
      }
    }
  }

  .about-us-homepage {
    display: flex;
    align-items: center;
    padding: 30px 15%;
    margin: 30px auto;
    background-color: $color1;
    a {
      color: $color2;
      transition: 0.5s;
      text-decoration: none;

      &:hover {
        color: $color2;
        text-decoration: underline;
      }
    }

    div:nth-child(1) {
      width: 50%;
      margin-right: 50px;
      animation: about-us 2.5s ease-in;

      img {
        width: 100%;
        object-fit: fill;
      }
    }

    div:nth-child(2) {
      width: 50%;
      font-style: italic;
      animation: about-us 1s ease-in;

      h2 {
        text-align: center;
        margin-bottom: 30px;
        color: #360237;
        font-weight: bold;
      }
    }
  }
}

@keyframes about-us {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.references {
  width: 100%;
  justify-content: space-around;
  display: flex;
  margin-bottom: 30px;
  background-color: $color1;
  padding: 30px 10%;

  div {
    img {
      width: 150px;
      height: 150px;
      object-fit: scale-down;
    }
  }
}

.our-services {
  background-color: #eaeaea;

  .our-services-body {
    display: grid;
    justify-content: center;
    padding: 24px 10%;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;

    .our-service {
      display: flex;
      align-items: center;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px $font1 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
      transition: .4s;
      border-radius: 10px;
      background-color: white;
      margin-inline: 20px;

      .image-part {
        text-align: center;
        width: 100%;
        width: 30%;
        margin-right: 5%;

        img {
          width: 90%;
        }
      }

      .right-part {
        width: 65%;
        font-size: 15px;

        div:nth-child(1) {
          font-size: 22px;
          font-weight: bold;
          color: #042d5a;
        }
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        cursor: pointer;
      }
    }
  }
}

.career-body {
  padding: 30px 10%;
  background-image: url("./assets/images/career-background.jpg");

  form {
    width: 60%;
    margin-inline: auto;

    div:nth-child(1) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      input,
      textarea,
      select {
        padding: 5px 10px;
        border: 2px solid #eaeaea;
        border-radius: 5px;
        outline: none;

        &:focus {
          border: 2px solid #9b819d;
        }
      }
    }

    div:nth-child(2) {
      width: 100%;
      text-align: right;
      margin-top: 20px;

      button {
        background-color: #360237bb;
        color: white;
        border: 1px solid #360237bb;
        border-radius: 5px;
        outline: none;
        padding: 5px 20px;
        transition: .4s;

        &:hover {
          background-color: #360237;
          border: 1px solid #360237;
        }
      }
    }
  }
}

.contact {
  background-color: #e5e5e5;
  padding-bottom: 20px;

  .contact-body {
    width: 80%;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    margin: 30px auto 0px;

    .contact-part {
      padding: 10%;
      // background: linear-gradient(#f03477, #b1496e);
      color: white;
      border-radius: 3px;

      i {
        font-size: 64px;
      }

      h5 {
        font-weight: bold;
        margin-block: 20px;
        writing-mode: horizontal-tb;
        text-transform: uppercase;
      }

      a {
        color: white;
        text-decoration: none;
        transition: .4s;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .contact-us-absolute {
    font-size: $font1 !important;
    background-color: white;
    margin-inline: auto;
    width: 60%;
    padding: 110px 10%;
    margin-top: -80px;

    p {
      text-align: center;
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    input,
    textarea {
      border: 1px solid transparent;
      border-bottom: 1px solid black;
      border-radius: 5px;
      padding: 3px 10px;
      outline: 0;
      margin-bottom: 50px;
      width: 100%;

      &:focus {
        border: 1px solid $color3;
      }
    }

    .input-part-flex {
      display: flex;

      input {
        width: 45%;
      }

      input:nth-child(1) {
        margin-right: 10%;
      }
    }

    button {
      padding: 10px;
      color: $color1;
      background-color: $color3-Hover;
      border: none;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        background-color: $color3;
      }
    }
  }
}

footer {
  width: 100%;
  display: block;

  p {
    margin-bottom: 5px;
  }

  .footer-up {
    display: flex;
    justify-content: space-around;
    background: linear-gradient(90deg, #042e5a8c, #816283);
    padding-block: 30px;
    font-size: $font1;
    color: $color1;

    .footer-up-left,
    .footer-up-right {
      width: 20%;
    }

    .footer-up-middle {
      width: 30%;
    }

    h3 {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: $color1;
      transition: 0.5s;
    }

    a:hover {
      color: $color2;
      text-decoration: underline;
    }

    .footer-up-left {
      a {
        margin-top: 5px;
        display: block;
      }
    }

    .footer-up-right {
      font-size: $font1 !important;
      input,
      textarea {
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 3px 10px;
        outline: 0;
        margin-bottom: 10px;
        &:focus {
          border: 1px solid $color3;
        }
      }

      .input-part-flex {
        display: flex;

        input {
          width: 45%;
        }

        input:nth-child(1) {
          margin-right: 10%;
        }
      }

      button {
        padding: 10px;
        color: $color1;
        background-color: $color3-Hover;
        border: none;
        border-radius: 10px;
        transition: 0.5s;

        &:hover {
          background-color: $color3;
        }
      }
    }
  }

  .footer-down {
    text-align: center;
    font-size: $font1;
    background-color: $color1;
    padding-block: 10px;
    color: $color2;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-caption {
    display: block !important;
    h4 {
      font-size: 40px !important;
    }
    p {
      font-size: 20px !important;
    }
  }
  .homePage {
    font-size: $font1;
    .about-us-homepage {
      div:nth-child(2) {
        h2 {
          font-size: 20px;
        }
        p {
          font-size: $font1;
        }
      }
    }
    .references {
      display: block;
      img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .about-us {
    .about-us-common-part {
      h3 {
        font-size: 20px;
      }
    }
  }

  .our-services {
    .our-services-body {
      grid-template-columns: 1fr;
      .our-service {
        .right-part {
          div:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            color: #042d5a;
          }
          div:nth-child(2) {
            font-size: $font1 !important;
          }
        }
      }
    }
  }

  .career {
    .career-body {
      padding-inline: 0;
      form {
        width: 80%;
      }
    }
  }

  .contact {
    .contact-body {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;

      .contact-part {
        font-size: $font1;
        i {
          font-size: 50px;
        }
      }
    }
    .contact-us-absolute {
      p {
        font-size: 38px;
      }
    }
  }

  footer {
    .footer-up {
      .footer-up-right {
        font-size: 12px !important;

        .input-part-flex {
          display: block;

          input {
            width: 100%;
          }

          input:nth-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .footer-down {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar{
    .container-fluid{
      .navbar-brand{
        img{
          width: 150px;
        }
      }
    }
  }
  .homePage {
    .carousel-caption {
      bottom: calc(25vh - 64px)!important;
    }

    .about-us-homepage {
      display: block;
      div {
        margin-inline: auto !important;
        width: 100% !important;
        margin-top: 30px;

        h2 {
          margin-bottom: 15px !important;
        }
      }
    }
  }

  .about-us {
    .about-us-common-part {
      width: 80%;
      overflow: hidden;
    }
  }

  .our-services {
    .our-services-body {
      .our-service {
        display: block;
        .image-part {
          width: 80%;
          margin-inline: auto;
          margin-bottom: 20px;
        }
        .right-part {
          width: 90%;
          margin-inline: auto;
          text-align: center;

          div:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .career {
    .career-body {
      form {
        div:nth-child(1) {
          grid-template-columns: 1fr;
          input,
          textarea,
          select {
            font-size: $font1;
          }
        }
      }
    }
  }

  .contact {
    .contact-body {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);

      .contact-part {
        font-size: $font1;
        i {
          font-size: 50px;
        }
      }
    }
    .contact-us-absolute {
      padding: 110px 8% 50px;
      p {
        font-size: 30px;
      }

      form {
        .input-part-flex {
          display: block;
          input {
            width: 100%;
          }
          input:nth-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }

  footer {
    .footer-up {
      display: block;

      .footer-up-left,
      .footer-up-right,
      .footer-up-middle {
        width: 70%;
        margin-inline: auto;
        margin-top: 30px;
        text-align: center;
      }
      .footer-up-right {
        font-size: 12px !important;

        .input-part-flex {
          display: block;

          input {
            width: 100%;
          }

          input:nth-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .footer-down {
      font-size: 12px;
    }
  }
}
